<template>
  <ModalComp
    :title-text="state.isUpdating ? 'Update Advertisement' : 'Add New Advertisement'"
    :show="state.showAction"
    @hide="fireEvent('hide')"
    @submit="handleDataSave()"
    title-icon-class="me-1 icon-airplay"
    :loading="state.formSubmitting"
  >
    <div class="row">
      <!--ad type-->
      <div class="form-group col-6">
        <label class="col-form-label">
          Ad Type:
        </label>
        <tree-select
          id="order-type"
          placeholder="Select Ad Type"
          v-model="state.form.adType.value"
          :options="state.adOptions"
          :multiple="false"
          :searchable="false"
          :clearable="false"
          value="node"
        />
        <FormErrorMsg :error="state.form.adType.error" />
      </div>

      <!--duration-->
      <div class="form-group col-6">
        <label for="duration" class="col-form-label">
          Display Duration:
          <span class="text-danger">*</span>
          <form-input-help>
            Show Ad for specified seconds in each cycle
          </form-input-help>
        </label>
        <input
          type="number"
          step="1"
          class="form-control"
          id="duration"
          placeholder="Duration"
          v-model.trim="state.form.duration.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.duration.error" />
      </div>

      <!--media-->
      <div class="form-group col-12">
        <label for="media" class="col-form-label">
          {{ state.form.adType.value === AD_TYPE_IMG ? 'Image' : 'YouTube Video ID' }}
          <span class="text-danger">*</span>
          <form-input-help>
            {{
              state.form.adType.value === AD_TYPE_IMG
                ? 'Choose image file for this ad'
                : 'Provide youtube video id'
            }}
          </form-input-help>
        </label>
        <FadeSlideAnimation duration="2">
          <div
            class="form-group"
            v-if="state.form.adType.value === AD_TYPE_IMG"
          >
            <div class="custom-file">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="upload Image"
                  readonly
                  :value="mediaName"
                  @click="() => fileInputRef.click()"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    @click="() => fileInputRef.click()"
                  >
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      class="d-none"
                      ref="fileInputRef"
                      @change="handleFileUpload"
                    />
                    Choose File
                  </button>
                </div>
              </div>
            </div>
          </div>

          <input
            v-else
            type="text"
            class="form-control"
            id="media"
            placeholder="Only Video ID"
            v-model.trim="state.form.media.value"
            autocomplete="off"
            required
          >


        </FadeSlideAnimation>
        <FormErrorMsg :error="state.form.media.error" />
      </div>

      <!--sort-->
      <div class="form-group col-4">
        <label for="sort" class="col-form-label">
          Sort:
          <span class="text-danger">*</span>
        </label>
        <form-input-help>Works on descending order</form-input-help>
        <input
          type="number"
          class="form-control"
          id="sort"
          placeholder="Sort position (descending)"
          v-model.trim="state.form.sort.value"
          autocomplete="off"
          required
        />
        <FormErrorMsg :error="state.form.sort.error" />
      </div>

      <!--sound-->
      <FadeSlideAnimation>
        <div
          v-if="state.form.adType.value !== AD_TYPE_IMG"
          class="form-group col-4"
        >
          <label class="col-form-label">
            Sound:
            <form-input-help>Disabling will mute sound</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="state.form.withSound"
              @change="val=>state.form.withSound=val"
              enable-text="Enabled"
              disable-text="Disabled"
            />
          </div>
        </div>
      </FadeSlideAnimation>

      <!--status-->
      <div class="form-group col-4">
        <label class="col-form-label">
          Status:
          <form-input-help>Disabling will hide this ad</form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="state.form.status"
            @change="val=>state.form.status=val"
            enable-text="Enabled"
            disable-text="Disabled"
          />
        </div>
      </div>
    </div>
  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { useStore } from 'vuex';
import { isEmptyObject } from '@/utils/Helper';
import Toaster from '@/utils/Toaster';
import { minValue, numeric, required, } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ErrorHelper from '@/utils/ErrorHelper';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import { stringIf, validFileIf } from '@/utils/CustomValidators';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';

export default defineComponent({
  name: 'InactivityAdAction',
  emits: ['updated', 'hide'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    itemToUpdate: {
      type: Object,
      default: () => {
      }
    }
  },

  components: { FadeSlideAnimation, FormErrorMsg, SwitchComp, FormInputHelp, ModalComp, TreeSelect },
  setup (props, { emit }) {

    const store = useStore();
    const AD_TYPE_IMG = 'img';
    const AD_TYPE_VIDEO = 'video';

    const state = reactive({

      showAction: false,
      isUpdating: false,

      form: {
        adType: {
          error: false,
          value: null,
        },
        duration: {
          error: false,
          value: 3
        },
        media: {
          error: false,
          value: null
        },
        sort: {
          error: false,
          value: 0
        },
        status: true,
        withSound: true,
      },

      adOptions: [
        {
          id: AD_TYPE_IMG,
          label: 'Image'
        },
        {
          id: AD_TYPE_VIDEO,
          label: 'Video'
        }
      ],

      formSubmitting: false

    });

    const fileInputRef = ref(null);
    const mediaName = computed(() => {
      // if an an is video
      if (state.form.adType === AD_TYPE_VIDEO) return state.form.media.value;

      // when updating & no file chosen
      if (state.isUpdating && !(state.form.media.value instanceof File)) return state.form.media.value;

      // return file name
      return state.form.media.value?.name;
    });

    // validation start
    const validationRules = {
      form: {
        adType: {
          value: {
            required,
          }
        },
        duration: {
          value: {
            required,
            numeric,
            minValue: minValue(0)
          }
        },
        media: {
          value: {
            required: !state.isUpdating,
            validFileIf: validFileIf(state.form.adType === AD_TYPE_IMG),
            stringIf: stringIf(state.form.adType !== AD_TYPE_IMG, 10, 20)
          }
        },
        sort: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      // map errors
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.adType.error = errors['form.adType.value'] || false;
          state.form.duration.error = errors['form.duration.value'] || false;
          // state.form.media.error = errors['form.media.value'] || false;
          state.form.sort.error = errors['form.sort.value'] || false;
        },
        {
          'form.adType.value': 'Ad Type',
          'form.duration.value': 'Display Duration',
        }
      );

    });
    // validation end

    const resetForm = () => {

      state.form = {
        adType: {
          error: false,
          value: null,
        },
        duration: {
          error: false,
          value: 3
        },
        media: {
          error: false,
          value: null
        },
        sort: {
          error: false,
          value: 0
        },
        status: true,
        withSound: true,
      };
    };

    const handleDataSave = () => {

      const data = {
        type: state.form.adType.value,
        duration: state.form.duration.value,
        media: state.form.media.value,
        sort: state.form.sort.value,
        status: state.form.status ? 1 : 0,
        with_sound: state.form.withSound ? 1 : 0,
      };

      createOrUpdateOffer(data);
    };

    const createOrUpdateOffer = async (data) => {

      state.formSubmitting = true;

      try {

        let response = state.isUpdating
          ? await store.dispatch('inactivityAd/updateAd', { data, id: props.itemToUpdate.id })
          : await store.dispatch('inactivityAd/createAd', data);

        Toaster.successAlt({
          message: response.data.message || 'Ad successfully saved'
        });

        fireEvent('updated');

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong. try again'
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.adType.error = ex(err.type);
          state.form.duration.error = ex(err.duration);
          state.form.media.error = ex(err.media);
          state.form.sort.error = ex(err.sort);
        });

      }

      state.formSubmitting = false;
    };

    const fireEvent = (event) => emit(event);

    const populateUpdateData = (nv) => {

      if (isEmptyObject(nv)) return;

      let newData = {
        adType: {
          error: false,
          value: nv.type,
        },
        duration: {
          error: false,
          value: nv.duration
        },
        media: {
          error: false,
          value: nv.media
        },
        sort: {
          error: false,
          value: nv.sort
        },
        withSound: nv.with_sound,
        status: nv.status,
      };

      state.form = newData;
    };

    const handleFileUpload = () => {
      const selectedFile = fileInputRef.value.files[0];
      state.form.media.value = selectedFile;
    };

    // reset media when ad type changed
    watch(() => state.form.adType.value, (nv, pv) => {

      // dont reset when opening for update
      if (pv === null) return;

      state.form.media.value = null;
    });

    watch(() => props.itemToUpdate, (nv) => {
      if (isEmptyObject(nv)) {
        resetForm();
        state.isUpdating = false;
        return;
      }

      state.isUpdating = true;
      populateUpdateData(nv);
    });

    watch(() => props.show, (nv) => state.showAction = nv);

    return {
      state,
      AD_TYPE_IMG,
      fileInputRef,
      mediaName,
      fireEvent,
      handleDataSave,
      handleFileUpload,
    };
  },
});
</script>

<style scoped lang="scss">
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

input[readonly] {
  outline: none;
  background-color: transparent;
}
</style>
