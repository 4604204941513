import { helpers } from '@vuelidate/validators';


/**
 * Generates custom validation
 * @param validatorCb : CallableFunction
 * @param extraParams : Object[{}]
 * @param message : String
 * @return helpers.withMessage
 */
function validatorGenerator (validatorCb, extraParams = {}, message = '') {

  const handler = helpers.withParams(extraParams, (value, vm) => validatorCb(value, vm));

  return helpers.withMessage(message, handler);
}


// @TODO: improve following validators to support array & objects

/**
 * Must contain the provided value
 * @param contains : String | Iterable | Number
 * @return helpers.withMessage
 */
export const mustContain = contains => validatorGenerator(
  value => !helpers.req(value) || value.toString().includes(contains),
  {
    type: 'mustContain',
    constraint: contains
  },
  `Must include '${contains}'`
);

/**
 * Must not contain the provided value
 * @param notContains : String | Iterable | Number
 * @return helpers.withMessage
 */
export const mustNotContain = notContains => validatorGenerator(
  value => !helpers.req(value) || !value.toString().includes(notContains),
  {
    type: 'mustNotContain',
    constraint: notContains
  },
  `Must exclude '${notContains}'`
);

/**
 * Checks if a file is valid when required in @param 1
 * @param shouldBeAFile
 * @return {helpers.withMessage}
 */
export const validFileIf = (shouldBeAFile = true) => validatorGenerator(

  // when required check if this is a file or always true
  value => shouldBeAFile ? (value instanceof File) : true,
  {
    type: 'validFileIf',
    constraint: shouldBeAFile
  },
  'Must be a file'
);

/**
 * Checks if a file is valid when required in @param 1
 * @return {helpers.withMessage}
 * @param shouldBeString : Boolean
 * @param minLength : Number
 * @param maxLength : Number
 */
export const stringIf = (shouldBeString = true, minLength = 1, maxLength = null) => validatorGenerator(

  // when required check if this is a file or always true
  value => {

    if (!shouldBeString) return true;

    const strLen = value.length;

    if (maxLength === null) return (strLen >= minLength);

    return (strLen >= minLength && strLen <= maxLength);
  },
  {
    type: 'stringIf',
    constraint: shouldBeString
  },
  (maxLength === null)
    ? `Must be minimum of ${minLength} characters.`
    : `Must be between ${minLength} to ${maxLength} characters.`
);

